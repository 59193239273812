import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { id: "add-investor-modal", fluid: "", tag: "section" } },
    [
      _c(
        VCard,
        { staticClass: "dxa_modal" },
        [
          _c(VCardTitle, { staticClass: "mb-2" }, [
            _c("h4", { staticClass: "dxa_modal_title h4" }, [
              _vm._v(_vm._s(_vm.$t("new_notification"))),
            ]),
          ]),
          _c(
            VCardText,
            [
              _c(
                VForm,
                {
                  ref: "form",
                  on: {
                    submit: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.submit()
                    },
                  },
                },
                [
                  _c(VCombobox, {
                    attrs: {
                      items: _vm.users,
                      disabled: _vm.edit || _vm.users.length == 1,
                      rules: [_vm.required],
                      "item-text": "Email",
                      "return-object": "",
                      label: _vm.$tc("user", 1),
                      "data-test":
                        "Notifications:AddSignatureNotification:InputUser",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.get_ongoing_opportunities()
                      },
                    },
                    model: {
                      value: _vm.userSelected,
                      callback: function ($$v) {
                        _vm.userSelected = $$v
                      },
                      expression: "userSelected",
                    },
                  }),
                  _c(VCombobox, {
                    attrs: {
                      disabled: _vm.edit,
                      items: _vm.companies,
                      rules: [_vm.required],
                      "item-text": "Name",
                      "item-value": "Id",
                      label: _vm.$tc("company", 1),
                      "data-test":
                        "Notifications:AddSignatureNotification:InputCompany",
                    },
                    model: {
                      value: _vm.companySelected,
                      callback: function ($$v) {
                        _vm.companySelected = $$v
                      },
                      expression: "companySelected",
                    },
                  }),
                  _c(VSelect, {
                    attrs: {
                      rules: [_vm.required],
                      items: _vm.currencyOptions,
                      "item-text": "text",
                      "item-value": "value",
                      label: _vm.$t("currency"),
                      dense: "",
                      "data-test":
                        "Notifications:AddSignatureNotification:SelectCurrency",
                    },
                    model: {
                      value: _vm.interest.Currency,
                      callback: function ($$v) {
                        _vm.$set(_vm.interest, "Currency", $$v)
                      },
                      expression: "interest.Currency",
                    },
                  }),
                  _c(VTextField, {
                    staticClass: "pt-0",
                    attrs: {
                      rules: [_vm.required],
                      label: _vm.$t("value"),
                      type: "number",
                      "data-test":
                        "Notifications:AddSignatureNotification:InputValue",
                    },
                    model: {
                      value: _vm.interest.Value,
                      callback: function ($$v) {
                        _vm.$set(_vm.interest, "Value", $$v)
                      },
                      expression: "interest.Value",
                    },
                  }),
                  _c(
                    VCardActions,
                    { staticClass: "pl-0 dxa_modal_actions" },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "dxa_modal_btnSuccess",
                          attrs: {
                            color: "primary",
                            "min-width": "100",
                            loading: _vm.loading,
                            type: "submit",
                            "data-test":
                              "Notifications:AddSignatureNotification:BtnSave",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]
                      ),
                      _c(
                        VBtn,
                        {
                          staticClass: "dxa_modal_btnError",
                          attrs: {
                            color: "red",
                            "min-width": "100",
                            "data-test":
                              "Notifications:AddSignatureNotification:BtnCancel",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("close", null)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.error
                ? _c(
                    VAlert,
                    { staticClass: "ma-2", attrs: { type: "error" } },
                    [_vm._v(_vm._s(_vm.$t(_vm.error)))]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }